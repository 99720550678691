/* General body styling */


/* Container for the product details */
.new-product-detail-container {
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  margin-top: 5%;
}

/* Breadcrumb navigation styling */
.new-breadcrumb {
  font-size: 14px;
  margin-bottom: 20px;
}

.new-breadcrumb a {
  color: #333;
  text-decoration: none;
}

.new-breadcrumb span {
  margin: 0 5px;
}

/* Product detail layout styling */
.new-product-detail {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.new-product-image {
  flex: 1;
  max-width: 50%;
  position: relative;
}

.new-product-image img {
  width: 100%;
  height: 100%;
}

/* Discount badge styling */
.new-discount-badge {
  background-color: #d3ffe9;
  color: #03753c;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
}

/* Product information section styling */
.new-product-info {
  flex: 1;
  margin-left: 2.5%;
  align-items: flex-start;
  text-align: left;
}

.new-product-info h1 {
  font-size: 28px;
  margin-bottom: 20px;
}

.new-price {
  font-size: 24px;
  margin-bottom: 20px;
}

.new-original-price {
  text-decoration: line-through;
  color: #888;
  margin-right: 10px;
}

.new-discounted-price {
  color: #e91e63;
  font-weight: bold;
}

/* Cart controls styling */
.new-cart-controls {
  margin-bottom: 20px;
  min-height: 40px; /* Ensure the area for controls is fixed in height */
}

.new-quantity-controls {
  display: flex;
  align-items: center;
}

.new-quantity-button {
  background-color: #ccc;
  border: 1px solid #bbb;
  color: #333;
  padding: 5px 15px;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.3s;
}

.new-quantity-button:hover {
  background-color: #bbb;
}

.new-quantity {
  margin: 0 10px;
  font-size: 16px;
}

.new-add-to-cart-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.3s;
}

.new-add-to-cart-button:hover {
  background-color: #45a049;
}

/* Button group styling */
.new-button-group {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.new-compare-button, .new-wishlist-button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.3s;
}

.new-compare-button:hover, .new-wishlist-button:hover {
  background-color: #0066cc;
}

/* Category information styling */
.new-category {
  margin-bottom: 20px;
  font-size: 14px;
}

/* Social share styling */
.new-social-share {
  margin-top: 20px;
  font-size: 14px;
}

.new-social-share a {
  color: #333;
  text-decoration: none;
  margin: 0 5px;
  font-size: 18px;
}

/* Divider line styling */
.last-Line {
  width: 100%;
}

/* Related products header styling */
.Related-Products {
  align-items: center;
  margin-top: 2%;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .new-product-detail {
    flex-direction: column;
    align-items: center;
  }

  .new-product-image {
    max-width: 100%;
  }

  .new-product-info {
    margin-left: 0;
    margin-top: 20px;
    text-align: center;
  }

  .new-product-info h1 {
    font-size: 24px;
  }

  .new-price {
    font-size: 20px;
  }

  .new-add-to-cart-button {
    width: 100%;
    padding: 10px;
    font-size: 18px;
  }

  .new-button-group {
    flex-direction: column;
    gap: 10px;
  }

  .new-compare-button, .new-wishlist-button {
    width: 100%;
    padding: 10px;
    font-size: 18px;
  }

  .new-breadcrumb {
    text-align: center;
    margin-bottom: 15px;
  }

  .new-quantity-controls {
    display: flex;
    align-items: center;
    margin-left: 28%;
  }

  .PD-body {
    font-family: Arial, sans-serif;
    background-color: #ffffff;
    margin-top: 15%;
    padding: 0;
  }

  .other-categories {
    margin-left: -20px;
  }
  .Related-Products {
    align-items: center;
    margin-top: 5%;
    font-size: medium;
  }
  
}
