/* CartPage.css */

/* General Styling */
.cartPage1 {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #f8f8f8;
  flex-direction: row; /* Default direction */
  margin-top: 5%;
}

.orderSummary1, .deliveryDetails1 {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.orderSummary1 {
  width: 69%;
  padding-left: 4vw;
  max-height: 85vh;
  overflow-y: auto;
}

/* Custom scrollbar for Webkit browsers (Chrome, Safari, Edge) */
.orderSummary1::-webkit-scrollbar {
  width: 3px;
}

.orderSummary1::-webkit-scrollbar-track {
  background: #ffffff;
}

.orderSummary1::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.orderSummary1::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Custom scrollbar width for Firefox */
.orderSummary1 {
  scrollbar-width: thin;
  scrollbar-color: #888 #ffffff;
}

.summaryHeader1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.totalItems1 {
  font-size: 16px;
  color: #555;
}

.cartItem1 {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.cartItemImage1 {
  width: 80px;
  height: 80px;
  margin-right: 20px;
}

.cartItemDetails1 {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.itemInfo1 {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.itemName1 {
  font-size: 15px;
  font-weight: 500;
  text-align: left;
  padding-left: 10px;
}

.itemPrice1 {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-top: 5px;
  text-align: left;
  padding-left: 10px;
}

.itemControls1 {
  display: flex;
  align-items: center;
}

.quantityButton1 {
  width: 30px;
  height: 30px;
  font-size: 20px;
  text-align: center;
  border: 1px solid #ddd;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #007bff;
  margin: 0 5px;
}

.quantityButton1:hover {
  background-color: #f0f0f0;
}

.itemQuantity1 {
  width: 30px;
  text-align: center;
}

.removeButton1 {
  color: red;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.deliveryDetails1 {
  width: 30%;
  max-height: 85vh;
}

.deliveryDetails1 h2 {
  margin-bottom: 10px;
}

.deliveryDetails1 form {
  display: flex;
  flex-direction: column;
}

.deliveryDetails1 form input,
.deliveryDetails1 form textarea,
.react-tel-input .form-control {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}

.deliveryDetails1 form textarea {
  margin-top: 10px;
}

.react-tel-input .form-control {
  width: calc(100% - 2.7vw);
  margin-left: 35px;
  margin-bottom: 10vh;
}

.priceDetails1 {
  margin-top: 20px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 15px;
  color: rgb(128, 128, 128);
}

.priceDetails1 h3 {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
  color: black;
}

.priceRow1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.totalPay1 {
  font-weight: bold;
  font-size: 15px;
  color: black;
  margin-top: 10px;
}

.minimumOrderMessage1 {
  background-color: #e8fff0;
  color: #008000;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 14px;
}

.placeOrderButton1 {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
}

.placeOrderButton1:hover {
  background-color: #0056b3;
}

.checkoutButton1 {
  background-color: rgb(255, 213, 0);
  color: black;
  border: none;
  padding: 10px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
}

.checkoutButton1:hover {
  background-color: rgb(128, 90, 0);
}

.dummyLine1 {
  width: 100%;
  color: rgb(190, 190, 190);
}

.errorMessage1 {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.alertMessage1 {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #f44336;
  color: white;
  padding: 15px;
  border-radius: 5px;
  z-index: 1000;
  font-size: 16px;
}


.mv-cartPage1{
  display: none;
}
/* ---------------------------------------------------------------------------------------- */

/* Mobile and Tablet Responsive Design */
@media screen and (max-width: 1024px) {
  .cartPage1 {
    flex-direction: column;
    margin-top: 15%;
    display: none;
  }


  .orderSummary1,
  .deliveryDetails1 {
    width: 100%;
    margin-bottom: 20px;
  }

  .react-tel-input .form-control {
    width: 100%;
    margin-left: 0;
  }
}

@media screen and (max-width: 768px) {
  .orderSummary1 {
    padding-left: 2vw;
    max-height: 45vh;
  }

  .cartItemImage1 {
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }

  .itemName1,
  .itemPrice1 {
    font-size: 14px;
    padding-left: 5px;
  }

  .quantityButton1,
  .itemQuantity1 {
    width: 25px;
    height: 25px;
    font-size: 18px;
  }

  .priceDetails1 h3 {
    font-size: 16px;
  }

  .priceRow1,
  .totalPay1 {
    font-size: 14px;
  }

  .placeOrderButton1 {
    font-size: 14px;
    padding: 8px;
  }
}

@media screen and (max-width: 480px) {
  .orderSummary1 {
    max-height: fit-content;
  }

  .cartItem1 {
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
  }

  .cartItemImage1 {
    width: 60px;
    height: 80px;
    margin-right: 10px;
    grid-column: 1;
    grid-row: span 3;
  }

  .itemInfo1 {
    grid-column: 2;
    grid-row: 1;
    display: flex;
    flex-direction: column;
  }

  .itemName1 {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    padding-left: 0;
    max-width: max-content;
  }

  .itemPrice1 {
    font-size: 14px;
    font-weight: bold;
    color: #ff00bb;
    text-align: left;
    padding-left: 0;
    margin-top: 5px;
  }

  .itemControls1 {
    grid-column: 2;
    grid-row: 2;
    display: flex;
    align-items: center;
    margin-top: 5px;
  }

  .quantityButton1 {
    width: 25px;
    height: 25px;
    font-size: 18px;
    margin: 0 5px;
  }

  .itemQuantity1 {
    width: 30px;
    text-align: center;
  }

  .removeButton1 {
    grid-column: 3;
    grid-row: span 2;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: red;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 0px;
    margin-bottom: 40%;
  }

  .cp-remove {
    display: none;
  }

  .quantityButton1:hover {
    background-color: #ff00bb;
    color: white;
  }

  .priceDetails1,
  .deliveryDetails1 {
    padding: 15px;
    margin-top: 0px;
  }

  .priceRow1,
  .totalPay1 {
    font-size: 14px;
  }

  .itemControls1 {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }

  .quantityButton1 {
    width: 30px;
    height: 30px;
    font-size: 20px;
    text-align: center;
    border: 1px solid #ddd;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border-radius: 50%;
    color: #007bff;
    margin: 0 5px;
  }

  .mv-cartPage1{
    flex-direction: column;
    margin-top: 15%;
    display: block;
   
    padding: 20px;
    background-color: #f8f8f8;
    flex-direction: row; /* Default direction */
  }
}
