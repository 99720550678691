body,
html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: none;
}

/* Container Styling */
.on-order-sale-container {
  display: flex;
  width: 100%;
  /* height: 100vh; */
}

/* Ensure images display at a larger size */
.on-summary-item-image img {
  object-fit: cover;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 50px;
}

.on-summary-item {
  display: flex;
  margin-bottom: 10px;
}

.order-line {
  width: 100%;
  border: 1px solid #ddd;
}

.on-summary-item-description {
  flex: 1;
  margin-left: 10px;
}

.on-summary-item-details {
  width: 20%;
  text-align: right;
}

/* Sidebar Styling */
.on-sidebar {
  width: 30%;
  padding: 20px;
  box-sizing: border-box;
  position: relative; /* Required for the pseudo-element to position relative to the parent */
}

.on-sidebar::before {
  content: "";
  position: absolute;
  right: 0;
  top: 20px; 
  height: 80vh; 
  width: 1px;
  background-color: #ddd;
}

.on-search-order {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.on-filter-buttons {
  display: flex;
  justify-content:flex-end;
  margin-bottom: 20px;
  gap: 3px;
  /* width: 22vw; */
}

.on-filter-button {
  /* width: 20px; */
  flex: 1;
  padding: 8px;
  border-radius: 50px;
  border: solid rgb(216, 213, 213) 0.3px;
  background-color: none;
  cursor: pointer;
  text-align: center;
  /* margin-right: 5px; */

}

.on-filter-button.on-selected {
  background-color: #292828b3;
  color: white;
}

.on-filter-button:last-child {
  margin-right: 0;
}

.on-order-list {
  margin-top: 10px;
  height: 63vh;
  overflow-y: auto;
}

.on-order-item {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}

.on-order-item.on-open {
  color: black;
}

.on-order-item.on-selected {
  border: 2px solid #007bff;
  background-color: #f7f7f7;
}

.on-order-info-left,
.on-order-info-right {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.on-order-info-right {
  align-items: flex-end;
}

.on-order-number {
  font-weight: bold;
  font-size: 16px;
}

.on-order-date {
  font-size: 12px;
  color: #888;
}

.on-order-customer,
.on-order-items,
.on-order-total {
  font-size: 14px;
  margin-bottom: 5px;
}

/* Order Details Styling */
.on-order-details {
  width: 70%;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

.on-order-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 14px;
  color: #666;
}

.on-order-info {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.on-order-info-item {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.on-order-info-label {
  font-weight: bold;
  margin-bottom: 5px;
  color: #007bff;
}

.on-order-info-value {
  color: #333;
}

.on-order-info-value::first-line {
  display: block;
}

/* Order Summary and Price Details Styling */
.on-order-summary-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.on-order-summary {
  width: 60%;
  padding: 20px;
  max-height: 250px;
  overflow-y: auto;
  text-align: left;
}

.on-order-summary::-webkit-scrollbar {
  width: 3px !important;
}

.on-order-summary::-webkit-scrollbar-track {
  background: #ffffff !important;
}

.on-order-summary::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 5px !important;
}

.on-order-summary::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.on-order-summary {
  scrollbar-width: thin !important;
  scrollbar-color: #888 #ffffff !important;
}

.on-summary-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-weight: bold;
}

.on-summary-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.on-summary-item-description {
  width: 70%;
}

.on-summary-item-details {
  width: 30%;
  text-align: right;
}

.on-price-details {
  width: 35%;
}

.on-price-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.on-price-item.on-total {
  font-weight: bold;
  font-size: 16px;
}

/* Order Actions Styling */
.on-order-actions {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: none;
}

.on-order-actions button {
  margin-left: 10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: none;
  border: none;
  cursor: pointer;
}

.on-order-actions button:hover {
  background-color: #6a6a6a;
  color: white;
}

.on-order-actions .on-convert-sale {
  background-color: #006cdf;
  color: white;
  border-radius: 50px;
}

.on-order-actions .on-deliver-order {
  background-color: red;
  color: #fff;
  border-radius: 50px;
}

.on-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
  height: 200px;
  width: 400px;
}

.on-popup-content {
  text-align: center;
  margin-top: 12%;
  font-size: 18px;
}

.on-popup-content p {
  margin: 0 0 20px;
}

.on-popup-content button {
  padding: 10px 20px;
  background: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.on-popup-content button:hover {
  background: #0056b3;
}

.on-open-tag,
.on-cancelled-tag,
.on-completed-tag {
  font-size: 10px;
  border-radius: 10px;
  padding: 3px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.on-deliver-order:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Mobile View */
@media (max-width: 768px) {
  .on-order-sale-container {
    flex-direction: column;
    margin-left: -7%;
    margin-bottom: 20%;
  }

  .on-sidebar {
    width: 90vw;
    border-right: none;
    border-bottom: 1px solid #ddd;
    padding: 0%;
  }

  .on-sidebar::before {
    display: none;
  }
  .on-order-details,
  .on-order-summary {
    width: 90vw;
  }

  .on-order-details {
    padding: 0px;
    margin-top: 15px;
  }

  .on-order-summary-container {
    flex-direction: column;
  }

  .on-order-actions {
    width: 100%;
    position: relative;
    bottom: auto;
    right: auto;
    padding: 10px;
  }

  .on-order-actions button {
    width: 100%;
    margin-left: 0;
    margin-bottom: 10px;
  }

  .on-search-order {
    margin-bottom: 10px;
  }

  .on-order-list {
    height: 30vh;
  }

  .on-order-info {
    display: flex;
    text-align: left;
    justify-content: space-between;
    font-size: 12px;
  }

  .on-summary-header {
    margin-bottom: 5px;
  }

  .on-order-summary {
    padding: 0px 10px 15px 0px;
  }

  .on-price-details {
    width: 90vw;
  }

  .on-order-actions {
    bottom: 0px;
    right: 0px;
    margin: 0 0;
    padding: 0px;
  }

  .on-order-actions button {
    margin-left: 10px;
    font-size: 14px;
  }
}
