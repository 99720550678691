.popup-box {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #0b2c5f;
  padding: 15px;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 100vw;
  width: 100%;
  z-index: 1000;
  color: white;
  font-size: 14px;
}

.ok-button {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #f1d600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.ok-button:hover {
  background-color: #c5ae00;
}
