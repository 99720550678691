.cl-carousel {
  margin: 20px auto;
  width: 85%;
  position: relative;
}

.cl-carousel img {
  width: 100%;
  border-radius: 10px;
  margin-top: 4%;
  position: relative;
  z-index: 5; /* Ensure images are below the fireworks */
}

.cl-home-image{
  margin-top: 5%;
}
@media (max-width: 768px) {
  .cl-carousel img {
    width: 100%;
    border-radius: 10px;
    margin-top: 20%;
  }
  .cl-slider-part {
    margin-top: -40px;
  }
}


