/* Existing CSS */
.ba-section {
    padding: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
}
/* Image */
.ba-image-content {
    width: 80%;
    max-width: 100%;
    text-align: center;
}
.ba-image1 {
    width: 50%;
    height: auto;
}

.ba-image2 {
    width: 50%;
    height: auto;
    margin-right: -10%;
}
/* Text */
.ba-text-content {
    width: 80%;
}
.ba-title {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: .7px;
    text-align: center;
}
.ba-title > span {
    position: relative;
}
.ba-title span::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #9D2553;
    border-radius: 8px;
    left: 0;
    bottom: -12px;
}
.ba-description {
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    letter-spacing: .8px;
    line-height: 1.8rem;
    text-align: justify;
    margin-left: -20%;
    margin-top: -5%;
    margin-right: 20%;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.ba-description1 {
    color: black;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    letter-spacing: .8px;
    line-height: 1.8rem;
    text-align: justify;
    margin-top: -5%;
    margin-right: -25%;
    margin-left: 20%;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}


/* Zigzag layout for the second section */
.ba-section.zigzag {
    flex-direction: row-reverse;
    margin-left: 2%;
}
/* Responsive */
@media screen and (max-width: 1000px) {
    .ba-section {
        display: block;
        padding: 16px;
    }
    .ba-image-content {
        width: 100%;
        max-width: 100%;
        margin-bottom: 16px;
    }
    .ba-text-content {
        width: 100%;
        margin-bottom: 16px;
    }
    .ba-image1, .ba-image2 {
        width: 100%;
        height: auto;
    }
    .ba-description, .ba-description1, .ba-description2 {
        margin: 0;
        text-align: justify;
        width: 90%;
        margin: auto;
        font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    }
    /* Responsive zigzag layout */
    .ba-section.zigzag {
        flex-direction: column;
        margin-left: 0;
    }
}
