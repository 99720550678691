body {
  font-family: Arial, sans-serif;
  background-color: #f7f7f7;
  margin: 0;
  padding: 0;
}

.qb-container {
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  margin-top: 5vh;
}

.qb-summary {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin-top: 5%;
}

.qb-summary-item {
  flex: 1;
  text-align: center;
  padding: 20px;
  color: white;
  margin: 0;
}

.qb-summary-item-u {
  flex: 1;
  text-align: center;
  padding: 20px;
  color: white;
  margin: 0;
}

.qb-summary-item:not(:last-child) {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.qb-summary-item-u:not(:last-child) {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.qb-summary-title {
  font-size: 15px;
}

.qb-summary-title-u {
  font-size: 15px;
}

.qb-summary-value {
  font-size: 20px;
  font-weight: bold;
}

.qb-summary-value-u {
  font-size: 20px;
  font-weight: bold;
}

.qb-category {
  margin-bottom: 40px;
}

.qb-category-title {
  display: block;
  position: relative;
  background: #c71b22;
  color: #FFF !important;
  padding: 9px 20px;
  font-size: 16px !important;
  font-weight: 700;
  line-height: 17px;
  word-break: break-word;
  background-color: #eb4c8e;
  white-space: nowrap; 
  width: 20vw;
}

.qb-category-title:after {
  position: absolute;
  content: "";
  top: 0;
  right: -20px;
  width: 0;
  height: 0;
  border-left: 20px solid #eb4c8e;
  border-top: 18px solid transparent;
  border-bottom: 19px solid transparent;
  font-family: Arial, sans-serif;
}

.qb-product-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: fixed;
}

.qb-product-table th,
.qb-product-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.qb-product-row {
  background: #fff;
  transition: background-color 0.2s;
}

.qb-product-row:hover {
  background-color: #f1f1f1;
}

.qb-product-image {
  width: 50px;
  height: auto;
}

.qb-product-info {
  padding: 10px;
  text-align: center;
}

.qb-discounted-price {
  color: red;
  font-weight: bold;
}

.qb-original-price {
  text-decoration: line-through;
  color: #888;
}

.qb-quantity-controls {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qb-quantity-button {
  background-color: #919191;
  border: none;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.qb-quantity {
  margin: 0 10px;
  font-size: 18px;
}

.qb-add-to-cart-button {
  background-color: #ffffff;
  border: 1px solid #949494;
  color: rgb(0, 0, 0);
  padding: 10px 40px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 25px; /* Higher value for oval shape */
}

.qb-add-to-cart-button:hover {
  background-color: #e1e1e1;
}

.qb-table-header {
  background-color: hsl(55, 100%, 83%);
  font-weight: bold;
}

hr {
  width: 8vw;
  margin: 0 auto;
  margin-top: 8px;
  margin-bottom: 5px;
}

.qb-category-title:after {
  border-left-color: #eb4c8e;
}

.qb-product-table .qb-image-column {
  width: 10vw; 
}

.qb-product-table .qb-image-column + td {
  width: 18vw;
}

.qb-price-m-display {
  display: none;
}

.qb-proceed-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #037dff;
  color: white;
  border: none;
  width: 60px; /* Adjust width and height for size */
  height: 60px;
  border-radius: 50%; /* Make the button round */
  cursor: pointer;
  font-size: 14px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.qb-proceed-button:hover {
  background-color: #4be802;
  color: black;
}

.qb-product-name-m{
  width: 35vw;
}

/* Add these styles in your QuickBuy.css or relevant CSS file */

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  z-index: 1000; /* Ensure it appears on top of other elements */
}

.modal h2 {
  margin-top: 0;
  font-size: 1.5rem;
  color: #333;
}

.modal p {
  margin: 20px 0;
  font-size: 1rem;
  color: #666;
}

.modal button {
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal button:hover {
  background-color: #0056b3;
}

/* Optional: Add a background overlay for better UX */
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 999; /* Ensure it appears below the modal but above other content */
}

@media (max-width: 480px) {
  .qb-container {
    width: 90%;
    margin: 0 auto;
    padding: 0px;
    margin-top: 5vh;
  }
  .qb-summary {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 30px;
    border-radius: 10px;
    font-size: 12px;
    overflow: hidden;
    height: 70px;
    width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .qb-summary-item {
    flex: 1;
    padding: 10px;
    color: white;
    margin: 0;
  }

  .qb-summary-item-u {
    display: none;
  }
  .qb-summary .qb-summary-title {
    font-size: 12px;
    margin-bottom: 30px;
  }

  .qb-summary .qb-summary-value {
    font-size: 12px;
    font-weight: bold;
    align-items: center;
  }

  .qb-break {
    display: none;
  }

  .qb-border {
    align-items: center;
    width: 100%;
  }

  .qb-table-header {
    background-color: hsl(55, 100%, 83%);
    font-weight: bold;
    width: 100%; /* Set the header width to 100% */
    height: fit-content;
  }

  .qb-product-table {
    width: 100%; /* Set the table width to 100% */
    border-collapse: collapse;
    margin-top: 20px;
  }

  .qb-product-table th,
  .qb-product-table td {
    border: 1px solid #ddd;
    padding: 10px;
    font-size: 12px;
  }

  
  .qb-container {
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    margin-top: 5vh;
  }

  .qb-category-title {
    font-size: 12px !important;
    white-space: nowrap;
    width: 94vw; 
  }

  .qb-category-title:after {
    border-left: 0;
    border-top: 0;
    border-bottom: 0;
  }

  .qb-product-table .qb-image-column {
    width: 18vw; 
    margin-left: 5vw;
  }

  .qb-product-table th,
  .qb-product-table td {
    border: 1px solid #ddd;
    padding: 10px;
  }

  .qb-product-row {
    background: #fff;
    transition: background-color 0.2s;
  }

  .qb-product-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }

  .qb-discounted-price-m {
    color: red;
    font-weight: bold;
  }

  .qb-original-price-m {
    text-decoration: line-through;
    color: #888;
  }

  .qb-quantity-controls {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .qb-quantity-button {
    background-color: #919191;
    border: none;
    color: white;
    padding: 2px 5px;
    cursor: pointer;
    border-radius: 5px;
  }

  .qb-quantity {
    margin: 0 8px;
    font-size: 15px;
  }

  .qb-add-to-cart-button {
    background-color: #ffffff;
    border: 1px solid #949494;
    color: rgb(0, 0, 0);
    padding: 2px 10px;
    text-decoration: none;
    font-size: 13px;
    cursor: pointer;
    border-radius: 20px; /* Higher value for oval shape */
    align-items: center;
    
  }

  .qb-product-row td {
    padding: 7px 4px;
  }

  .qb-price-m-display {
    display: inline-table;
  }

  .qb-price-display {
    display: none;
  }
  .qb-price {
    display: none;
  }

  .qb-product-name-m {
    max-width: 30vw; 
  }

  .qb-qty-m {
    min-width: 100px;
  }

}
