/* Common styles for all screen sizes */
.TableRow {
  position: relative;
}

.TableCell {
  transition: all 0.3s ease;
}

.TableCell:hover .Actions {
  opacity: 1;
}

.Actions {
  opacity: 0;
  transition: all 0.3s ease;
  position: absolute;
  right: 0;
}

/* Styles for larger screens (laptops) */
.save_and_deliver {
  margin-left: 72.6%;
  display: flex;
  flex-direction: row;
}

.table-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px; /* Adjust the max-width as needed */
}


/* Styles for smaller screens (mobile devices) */
@media (max-width: 600px) {
  .Actions {
    position: static;
    opacity: 1;
    display: flex;
    justify-content: flex-end;
  }

  .save_and_deliver {
    margin-left: 0;
  }

  .save_and_deliver > button,
  .save_and_deliver > .ButtonGroup {
    width: 100%;
    margin-bottom: 10px;
    padding: 2.3%;

  }
}
