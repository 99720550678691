* {
  margin: 0;
  padding: 0;
}

.fp-add-party-form {
  margin: 40px;

  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(39, 128, 230, 0.1);
  margin-left: 1%;
  margin-top: 0%;
  display: flex;
  flex-direction: column;
  
}

.fp-add-party-form h2 {
  text-align: left;
  margin-bottom: 20px;
}

.fp-add-party-form form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  
}

.fp-input-container {
  position: relative;
  margin-bottom: 10px;
  width: 80%;
  margin-top: 40px;
}

.fp-input-containerFirst {
  position: relative;
  margin-bottom: 10px;
  width: 20%;
  margin-top: 40px;
  margin-right: 8%;
}
.fb-email {
  width: 400%;
}

.fp-input-placeholder {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease;
  color: #999;
  pointer-events: none;
  
}

.fp-input-placeholder.active {
  top: -10px;
  font-size: 12px;
  color: #007bff;
}

input,
textarea,
select {
  padding: 10px;
  font-size: 16px;
  width: 250%;
  box-sizing: border-box;
  border: 1px solid #cccccc;
  border-radius: 8px;
  transition: border-color 0.3s ease, border-width 0.3s ease;
}

input:focus,
textarea:focus,
select:focus {
  border-color: #007bff;
  border-width: 2px;
  outline: none;
  /* Optional: Remove the default outline */
}

textarea {
  height: 60px;
  width: 10vw;
}

.fp-form-actions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.fp-form-actions button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
}

.fp-form-actions button:hover {
  background-color: #0056b3;
}

.fp-gst-address {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

/* Add.css */
.fp-enable-shipping {
  cursor: pointer;
  color: #007bff;
  margin-right: 63%;
}

.fp-enable-shipping.active {
  color: rgb(131, 128, 128);
}

.fp-enable-shipping input {
  margin-right: 0px;
  display: contents;
  color: #007bff;
}

.fp-shipping-address {
  margin-bottom: 7%;
}

.fp-container1 {
  display: flex;
  justify-content: space-around;
}

.fp-input-with-button {
  position: relative;
  display: flex;
  align-items: center;
}

.fp-input-with-button input {
  padding-right: 40px;
  /* Adjust padding to make room for the button */
}

.fp-info-button {
  position: absolute;
  right: 10px;
  width: 16px;
  height: 16px;
  background-color: rgb(170, 168, 168);
  border: 2px solid #97999b;
  border-radius: 50%;
  font-size: 9px;
  color: #fdfeff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  /* Optional: Remove the default outline */
}

.fp-info-button:hover .fp-tooltip {
  display: block;
}

.fp-tooltip {
  display: none;
  position: absolute;
  right: 20px;
  top: 0;
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 4px;
  width: 200px;
  z-index: 1000;
  text-align: justify;
  font-size: 10px;
  margin-top: 26px;
  margin-left: 30px;
}

.fp-line {
  display: flex;
  justify-content: space-between;
}

.fp-line2 {
  margin-right: 20%;
  width: 50%;
}

.fp-vertical-line {
  border: none;
  border-left: 1px solid #ccc;
  /* Adjust the thickness and color */
  height: 300px;
  /* Adjust the height */
  margin-right: 60px;
  /* Adjust the margin */
}

.fp-end hr {
  color: 2px solid #f50707;
  width: 100%;
}
