.LP-app-container {
  height: 55vh;
  display: flex;
  justify-content: end;
  align-items: center;
  background: white;
}

.LP-auth-box {
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.LP-1);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.LP-auth-title {
  margin-bottom: 20px;
  color: #333;
  top: -2vh;
}

.LP-auth-form {
  display: flex;
  flex-direction: column;
}

.LP-auth-form a {
  color: blue;
}

.LP-auth-form a:hover {
  color: rgb(255, 0, 144);
}

.LP-auth-input {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.LP-auth-button {
  padding: 10px;
  margin-top: 20px;
  border: none;
  border-radius: 4px;
  background-color: #4caf50;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.LP-auth-button:hover {
  background-color: #45a049;
}

.LP-auth-admin {
  color: springgreen;
  text-shadow: #333;
  font-size: small;
}

.LP-auth-admin:hover {
  color: black;
}

.LP-auth-register {
  font-size: small;
}

.close-button {
  position: fixed;
  top: 22%;
  right: 40%;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.close-button:hover {
  color: red;
}


/*--------------------------*/

.LP-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  outline: none;
}

.LP-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.LP-75);
}





.LP-back-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: blue;
}

.LP-back-button:hover {
  color: darkblue;
}

.LP-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: red;
}

.LP-close-button:hover {
  color: darkred;
}

@media (max-width: 480px) {
  .LP-auth-box {
    background: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.LP-1);
    max-width: 100%;
    width: 100%;
    text-align: center;
  }

  .close-button {
    position: fixed;
    top: 22%;
    right: 10%;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .close-button:hover {
    color: red;
  }
  
}
