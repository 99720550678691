/* Base styles for the DeliveryDetails component */

  /* Styles specific for mobile view */
  @media (max-width: 767px) {
    .deliveryDetails1 {
      max-width: 100%;
      padding: 10px;
      border-radius: 0;
      margin-top: 20%;
    }
  
    .deliveryDetails1 h2 {
      margin-bottom: 15px;
      font-size: 1.2em;
      color: #333;
    }
  
    .deliveryDetails1 form {
      display: flex;
      flex-direction: column;
    }
  
    .deliveryDetails1 form input,
    .deliveryDetails1 form textarea {
      padding: 10px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 0.9em;
    }
  
    .deliveryDetails1 form textarea {
      height: 80px;
    }
  
    .errorMessage1 {
      color: red;
      font-size: 0.8em;
      margin-bottom: 8px;
    }
  
    .priceDetails1 {
      margin-top: 15px;
      background-color: #fff;
      padding: 10px;
      border-radius: 5px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }
  
    .priceDetails1 h3 {
      margin-bottom: 8px;
      font-size: 1em;
      color: #333;
    }
  
    .priceRow1 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      font-size: 0.9em;
    }
  
    .totalPay1 {
      font-weight: bold;
      font-size: 1em;
    }
  
    .minimumOrderMessage1 {
      margin-top: 8px;
      color: red;
      font-size: 0.8em;
    }
  
    .placeOrderButton1 {
      padding: 10px;
      background-color: #28a745;
      color: #fff;
      border: none;
      border-radius: 25px;
      cursor: pointer;
      font-size: 1em;
      margin-top: 10px;
    }
  
    .placeOrderButton1:hover {
      background-color: #218838;
    }

/* Add styling for the PhoneInput component */
.phoneInputContainer {
  display: flex;
  flex-direction: column;
}

.phoneInputContainer input {
  width: 100% !important;
  padding: 10px !important;
  padding-left: 45px !important;

  font-size: 16px !important;
}

.phoneInputContainer .form-control {
  width: 100%;
}

.phoneInputContainer .errorMessage1 {
  margin-top: -10px;
  margin-bottom: 10px;
}
  }
  