/* CustomModal.css */
.cm-modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cm-modalContent {
  background: #ffffff; /* White background */
  padding: 25px 35px;
  border-radius: 15px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 400px;
  width: 80%;
  font-family: 'Arial', sans-serif;
  position: relative;
  animation: fadeIn 0.5s ease-in-out;
}

.cm-closeButton {
  background-color: #007bff; /* Sky blue background */
  color: #fff;
  border: none;
  padding: 12px 25px;
  border-radius: 30px;
  cursor: pointer;
  margin-top: 25px;
  font-size: 18px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.cm-closeButton:hover {
  background-color: #007bff; /* Darker sky blue on hover */
  transform: scale(1.05);
}

.cm-modalContent p {
  font-size: 18px;
  color: #333;
  margin-bottom: 25px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}