.categories-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5%;
  padding: 60px;
  margin-bottom: 5%;
}

.categories-grid .card {
  max-width: 75%;
  height: 100%;
  font-size: 12px;
  padding-top: 5%;
  margin-left: 12.5%;
}

.categories-grid .card .card-image {
  margin-top: 5px;
  max-width: 85%;
  height: 80%;
  border-radius: 25px;
}

.categories-grid .card .card-title {
  margin-top: 5%;
  font-size: 14px;
}

.categories-grid-mv {
  display: none;
}

@media (max-width: 768px) {
  .categories-grid-mv {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
  }

  .categories-grid-mv .card {
    max-width: 100%;
    max-height: 100%;
    font-size: 12px;
    padding-top: 7%;
  }

  .categories-grid {
    display: none;
  }
}

@media (max-width: 480px) {
  .categories-grid-mv {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 0px;
    margin-left: 7%;
    margin-right: 7%;
  }

  .categories-grid-mv .card {
    max-width: 100%;
    max-height: 100%;
    font-size: 12px;
    padding-top: 7%;
  }

  .categories-grid {
    display: none;
  }

  .categories-grid-mv .card .card-image {
    border-radius: 20px;
  }

  .categories-grid-mv .card .card-title {
    padding-top: 3px;
    padding-bottom: 10px;
    font-size: 12px;
  }
}