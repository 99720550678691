*{
  margin: 0%;
  padding: 0%;
}
.nav-bar {
  display: flex;
  justify-content: space-around;
  border-bottom: 2px solid #ccc;
  margin-bottom: 20px;
  margin-top: 2vh;
  
}

.nav-item {
  padding: 10px 20px;
  cursor: pointer;
  position: relative;
  font-weight: normal;
  color: #333;
  margin-right: 180px;
}

.nav-item.active {
  color: #007bff;
  font-weight: bold;
}

.nav-item.active::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 4px;
  background-color: #007bff;
}
