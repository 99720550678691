* {
    margin: 0;
    padding: 0;
  }
  
  .RP-vh-100 {
    height: 100vh;
  }
  
  .RP-bg-light {
    background-color: #eee !important;
  }
  
  .RP-card {
    border-radius: 25px;
  }
  
  .RP-text-black {
    color: #000 !important;
  }
  
  .RP-fp-input-container {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
  }
  
  input,
  textarea,
  select {
    padding: 10px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #cccccc;
    border-radius: 8px;
    transition: border-color 0.RP-3s ease, border-width 0.RP-3s ease;
  }
  
  input:focus,
  textarea:focus,
  select:focus {
    border-color: #007bff;
    border-width: 2px;
    outline: none;
  }
  
  textarea {
    height: 60px;
  }
  
  .RP-fp-form-actions {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
  
  .RP-fp-form-actions button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
  }
  
  .RP-fp-form-actions button:hover {
    background-color: #0056b3;
  }
  
  .RP-img-fluid {
    width: 65%;
    height: auto;
  }
  
  .RP-me-3 {
    margin-right: 1rem !important;
  }
  
  .RP-fa-fw {
    width: 1.RP-28571429em;
    text-align: center;
  }
  
  .RP-fa-lg {
    font-size: 1.RP-33333333em;
    line-height: 0.RP-75em;
    vertical-align: -15%;
  }
  
  .RP-register-img {
    max-height: 100%;
    object-fit: contain;
    align-self: flex-end;
  }
  
  .RP-align-items-center {
    display: flex;
    align-items: center;
  }
  
  .RP-justify-content-center {
    display: flex;
    justify-content: center;
  }
  