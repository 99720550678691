.CP-about-section {
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  background: linear-gradient(to right, #ffffff, #FBFCFF);
  margin-top: 4%;
}

/* Image / Map */
.CP-about-image-content {
  width: 50%;
  max-width: 100%;
  text-align: center;
}

.MV-CP-about-image-content {
 display: none;
 width: 50%;
 max-width: 100%;
 text-align: center;
}
.CP-about-map {
  width: 80%;
  height: 450px;
  border: 0;
  border-radius: 8px; /* Optional: add rounded corners */
}

/* Text */
.CP-about-text-content {
  width: 50%;
}
.CP-about-title {
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  font-weight: bold;
  text-align: left;
}
.CP-about-title > span {
  position: relative;
}
.CP-about-title span::before {
  content: '';
  position: absolute;
  width: 75%;
  height: 4px;
  background-color: #9D2553;
  border-radius: 8px;
  left: 0;
  bottom: -12px;
}
.CP-about-description {
  margin: 32px 0;
  color: #4d4b4b;
  font-family: 'Rubik', sans-serif;
  font-size: 18px;
  letter-spacing: .7px;
  line-height: 1.7rem;
  text-align: left;
}
.CP-about-text-title {
  margin: 0 0 24px 0;
  color: #4d4b4b;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: .7px;
  text-align: left;
}
/* Steps */
.CP-about-text-step {
  margin: 16px 0;
  text-align: left;
}
.CP-about-text-sTitle {
  font-family: 'Rubik', sans-serif;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: .7px;
  text-align: left;
}
.CP-about-text-description {
  margin: 8px 24px;
  color: #4d4b4b;
  font-family: 'Rubik', sans-serif;
  letter-spacing: .7px;
  line-height: 1.6rem;
  text-align: left;
}
.fa-icon {
  color: #9D2553;
}
/* Responsive */
@media screen and (max-width: 1000px) {
  .CP-about-section {
    display: block;
    margin-top: 15%;
  }
  .CP-about-image-content {
    width: 100%;
    max-width: 100%;
    margin: 0 0 32px 0;
    display: inline;
  }

  .CP-about-image-content {
    display: none;
  }

  .MV-CP-about-image-content {
    display: inline;
    width: 50%;
    max-width: 100%;
    text-align: center;
  }
  .CP-about-map {
    width: 100%;
    height: auto;
  }
  .CP-about-text-content {
    width: 100%;
  }
}
