.App-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    font-family: 'Arial', sans-serif;
  }
  
  .App-header p {
    padding-top: 5%;
    margin: 0 10px; /* Add horizontal margin to create space between the text and the lines */
    font-size: 18px;
    color: #333;
    white-space: nowrap; /* Prevent the text from wrapping */
  }
  
  .line {
    flex-grow: 1;
    height: 1px;
    background-color: #000000;
    margin-top: 5%;
  }
  .line1{
    margin-left: 5%;
  }
  .line2{
    margin-right: 5%;

  }