.AP-about-section {
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  background: linear-gradient(to right, #ffffff, #FBFCFF);
  margin-top: 4%;
}
/* Image */
.AP-about-image-content {
  width: 50%;
  max-width: 100%;
  text-align: center;
}
.AP-about-image1 {
  width: 80%;
  height: auto;
}
/* Text */
.AP-about-text-content {
  width: 50%;
}
.AP-about-title {
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  font-weight: bold;
  text-align: left;

}
.AP-about-title > span {
  position: relative;
}
.AP-about-title span::before {
  content: '';
  position: absolute;
  width: 75%;
  height: 4px;
  background-color: #9D2553;
  border-radius: 8px;
  left: 0;
  bottom: -12px;
}
.AP-about-description {
  margin: 32px 0;
  color: #4d4b4b;
  font-family: 'Rubik', sans-serif;
  font-size: 18px;
  letter-spacing: .7px;
  line-height: 1.7rem;
  text-align: left;

}
.AP-about-text-title {
  margin: 0 0 24px 0;
  color: #4d4b4b;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: .7px;
  text-align: left;

}
/* Steps */
.AP-about-text-step {
  margin: 16px 0;
  text-align: left;

}
.AP-about-text-sTitle {
  font-family: 'Rubik', sans-serif;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: .7px;
  text-align: left;
}
.AP-about-text-description {
  margin: 8px 24px;
  color: #4d4b4b;
  font-family: 'Rubik', sans-serif;
  letter-spacing: .7px;
  line-height: 1.6rem;
  text-align: left;

}
.fa-icon {
  color: #9D2553;
}
/* Responsive */
@media screen and (max-width: 1000px) {
  .AP-about-section {
      display: block;
      margin-top: 15%;

  }
  .AP-about-image-content {
      width: 100%;
      max-width: 100%;
      margin: 0 0 32px 0;
  }
  .AP-about-image1 {
      width: 100%;
      height: 100%;
  }
  .AP-about-text-content {
      width: 100%;
  }
}
