* {
  margin: 0;
  padding: 0;
}

.OS-hd-nav-bar {
  display: flex;
  justify-content: space-evenly;
  border-bottom: 2px solid #ccc;
  margin-bottom: 15px;
  /* margin-top: 2vh; */
  flex-wrap: wrap; /* Allow wrapping if needed */
}

.OS-hd-nav-item {
  padding: 10px 20px;
  cursor: pointer;
  position: relative;
  font-weight: bold;
  color: #333;
  margin-right: 180px;
}

.OS-hd-nav-item.active {
  color: #008cff;
  font-weight: bold;
}

.OS-hd-nav-item.active::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 4px;
  background-color: #008cff;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .OS-hd-nav-bar {
    justify-content: center; /* Center items */
    flex-wrap: nowrap; /* Prevent wrapping */
    margin-left: -10px;

  }

  .OS-hd-nav-item {
    margin-right: 0px; /* Adjust margin */
    padding: 10px; /* Adjust padding */
  }
}

@media (max-width: 480px) {
  .OS-hd-nav-bar {
    justify-content: space-between; /* Evenly space items */
    flex-direction: row; /* Ensure horizontal layout */
    align-items: center; /* Center items */
    margin-right: 0px;
    margin-left: -15px;
  }

  .OS-hd-nav-item {
    margin-bottom: 0; /* Remove bottom margin */
  }
}
