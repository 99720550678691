/* Similar to CartPage.css with minor modifications for FavoritesPage */

.favoritesPage1 {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #f8f8f8;
  flex-direction: row;
  width: 70%;
  margin: 0 auto;
  margin-top: 5%;

}

.orderSummary1 {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.summaryHeader1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.totalItems1 {
  font-size: 16px;
  color: #555;
}

.cartItem1 {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.cartItemImage1 {
  width: 80px;
  height: 80px;
  margin-right: 20px;
}

.cartItemDetails1 {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.itemInfo1 {
  display: flex;
  flex-direction: column;
}

.itemName1 {
  font-size: 15px;
  font-weight: 500;
}

.itemPrice1 {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-top: 5px;
}

.removeButton1 {
  color: red;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.Fv-add-to-cart-button {
  display: block;
  background-color: #ffffff;
  border: 1px solid #949494;
  color: #165978;
  padding: 5px 40px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin-top: 5px;
  cursor: pointer;
  border-radius: 25px;
  transition: background-color 0.3s;
  font-weight: bold;
  margin-right: 5.5%;

}

/* Hover effect for Add to Cart button */
.Fv-add-to-cart-button:hover {
  background-color: #e1e1e1;
}

.itemControls1 {
  display: flex;
  align-items: center;
  margin-right: 6%;
}

@media screen and (max-width: 1024px) {
  .favoritesPage1 {
    flex-direction: column;
    margin-top: 15%;
  }

  .orderSummary1 {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .orderSummary1 {
    padding-left: 2vw;
    max-height: 45vh;
  }

  .cartItemImage1 {
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }

  .itemName1,
  .itemPrice1 {
    font-size: 14px;
  }

  .removeButton1 {
    font-size: 12px;
  }


}

@media screen and (max-width: 480px) {
  .orderSummary1 {
    max-height: fit-content;
  }

  .cartItem1 {
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
  }

  .cartItemImage1 {
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }

  .itemName1 {
    font-size: 14px;
  }

  .itemPrice1 {
    font-size: 14px;
  }

  .removeButton1 {
    font-size: 12px;
  }


  .Fv-add-to-cart-button {
    padding: 6px 25px;
    margin-right: -12%;
    margin-left: 3%;

  }

  .itemControls1 {
    margin-right: -12%;
    margin-left: 5%;
  }

  .quantityButton1 {
    width: 25px;
    height: 25px;
    margin: 0 0px;
  }

  .favoritesPage1 {

    background-color: #f8f8f8;
    flex-direction: row;
    width: 100%;
    margin: 0 auto;
    margin-top: 15%;

  }

}