/* Common styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.logo h2 {
  margin: 0;
  font-size: 22px;
  color: #ff4081;
  cursor: pointer;
}

.logo .highlight {
  color: #007bff;
}

.nav a {
  margin: 0 15px;
  text-decoration: none;
  color: #333;
}

.nav a.active {
  color: red;
}

.auth-cart a {
  margin: 0 10px;
  text-decoration: none;
  color: #333;
}

.cart-icon-container,
.favorites-icon-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.cart-count,
.favorites-count {
  position: absolute;
  top: -12px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  font-size: 12px;
  height: 16px;
  width: 16px;
  padding-bottom: 2px;
}

.favorites-count {
  right: 5px;
}

.cart-dropdown {
  position: absolute;
  top: 38px;
  right: -14px;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 350px;
  z-index: 1;
  font-size: 14px;
  max-height: 300px;
  overflow-y: auto;
}

.favorites-dropdown {
  position: absolute;
  top: 38px;
  right: -32px;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 350px;
  z-index: 1;
  font-size: 14px;
  max-height: 300px;
  overflow-y: auto;
}

/* Custom scrollbar styles */
.cart-dropdown::-webkit-scrollbar,
.favorites-dropdown::-webkit-scrollbar {
  width: 8px;
}

.cart-dropdown::-webkit-scrollbar-track,
.favorites-dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.cart-dropdown::-webkit-scrollbar-thumb,
.favorites-dropdown::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.cart-dropdown::-webkit-scrollbar-thumb:hover,
.favorites-dropdown::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.cart-item,
.favorites-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  word-break: break-word;
}

.cart-item-image,
.favorites-item-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
}

.cart-item-details,
.favorites-item-details {
  margin-left: 10px;
  flex-grow: 1;
}

.cart-item > p,
.favorites-item > p {
  margin: 0;
  max-width: 120px;
  white-space: normal;
  overflow-wrap: break-word;
}

.cart-quantity-controls {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;

  background-color: #f9f9f9;
  width: 50%;
  text-align: center;
  margin-left: 25%;
  
}

.quantity-button {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.quantity-button:hover {
  background-color: #e0e0e0;
}

.quantity-button-minus {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 100%;
  padding: 3px 7px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.quantity-button-minus:hover {
  background-color: #ef444486;
}

.quantity-button-plus {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 100%;
  padding: 3px 7px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.quantity-button-plus:hover {
  background-color: #b2eda6;
}

.quantity {
  margin: 0 10px;
  font-weight: bold;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff;
}

.cart-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  padding-top: 10px;
  border-top: 1px solid #ddd;
}

.view-cart-button {
  display: block;
  width: 100%;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 25px;
}

.view-cart-button:hover {
  background-color: #0056b3;
}

/* Additional improvements for item alignment */
.cart-item > p:first-child,
.favorites-item > p:first-child {
  flex: 1;
}

.quantity-controls {
  flex-shrink: 0;
}

.cart-item > p:last-child,
.favorites-item > p:last-child {
  flex-shrink: 0;
  margin-left: 10px;
}

.login-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.login-popup-inner {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
}

.m-space {
  display: none;
}

.login_form{
  display: none;
}

/* Mobile styles */
@media (max-width: 768px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 1000;
  }

  .logo h2 {
    margin: 0;
    font-size: 18px;
    color: #ff4081;
  }

  .cart-icon-container,
  .favorites-icon-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  .cart-count,
  .favorites-count {
    position: absolute;
    top: -12px;
    right: -5px;
    background-color: red;
    color: white;
    border-radius: 50%;
    font-size: 12px;
    height: 16px;
    width: 16px;
    padding-bottom: 2px;
  }

  .favorites-count {
    right: 5px;
  }

  .mobile-menu-button {
    display: block;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #333;
    margin-bottom: 5px;
  }

  .nav {
    display: none;
    flex-direction: column;
    position: fixed;
    top: 7.5%;
    left: 0;
    width: 200px; /* Set a fixed width for the menu */
    height: 1000px; /* Set a fixed height for the menu */
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    z-index: 1000;
    overflow-y: auto;
    text-align: left; /* Align the menu items to the left */
  }

  .nav.open {
    display: flex;
  }

  .nav a {
    margin: 10px 0;
    padding: 10px 20px;
    color: #333;
    text-decoration: none;
    border-bottom: 1px solid #ddd;
  }

  .auth-cart {
    display: flex;
    align-items: center;
  }

  .m-space {
    display: none;
  }

  .cart-dropdown {
    position: absolute;
    background: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 250px;
    z-index: 1;
    font-size: 14px;
    max-height: 300px;
    overflow-y: auto;
  }
  
  .favorites-dropdown {
    position: absolute;
    background: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 250px;
    z-index: 1;
    font-size: 14px;
    max-height: 300px;
    overflow-y: auto;
  }

  .cart-quantity-controls {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding-left: 13px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #f9f9f9;
    width: 80%;
    text-align: center;
    margin-left: 10%;
    
  }
}

/* Tablet and desktop styles */
@media (min-width: 768px) {
  .mobile-menu-button {
    display: none;
  }

  .nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
    position: static;
    width: auto;
    box-shadow: none;
    padding: 0;
    height: auto;
    flex-direction: row;
    margin-right: 20px;
    z-index: 0; /* Reset z-index for laptop view */
  }

  .nav a {
    border: none;
    padding: 0;
    margin: 0;
  }
}

@media (max-width: 480px) {
  .m-space {
    display: block;
  }

  .lp-space {
    display: none;
  }
}
