.card {
    background: white;
    border-radius: 10px;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.3);
    text-align: center;
    transition: transform 0.2s;
    margin-bottom: 5%;
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  
  .card-image {
    max-width: 200px;
    height: 240px;
  }
  
  .card-title {
    font-family: 'Arial', sans-serif;
    font-size: 1.2em;
    color: #333;
  }
  
  @media (max-width:768px) {
    .card-image {
      max-width: 130px;
      height: 150px;
    }
    
  }