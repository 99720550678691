.Ic-contact-icons {
  display: flex;
  position: absolute;
  left: 82vw;
  margin-top: -35px;
  z-index: 1;
  margin-right: 30vw;
}

.Ic-Dial-icon {
  cursor: pointer;
  text-align: center;
}

.Ic-Dial-icon img {
  width: 40px; /* Adjust icon width */
  height: 40px; /* Adjust icon height */
  display: block;
  border-radius: 30px;
  margin-top: 12vh;
  position: fixed;
  margin-left: 13vw;
  filter: drop-shadow(1px 1px 10px green);
}

.Ic-Dial-icon:hover {
  opacity: 0.8; /* Reduce opacity on hover */
  filter: drop-shadow(1px 1px 10px blue);
}

.Ic-Whatsapp-icon {
  cursor: pointer;
}

.Ic-Whatsapp-icon img {
  width: 50px; /* Adjust icon width */
  height: 50px; /* Adjust icon height */
  display: block;
  margin-top: 23vh; 
  position: fixed;
  margin-left: 12.8vw;
  filter: drop-shadow(1px 1px 10px rgb(14, 14, 14));
}

.Ic-Whatsapp-icon:hover {
  opacity: 0.8; /* Reduce opacity on hover */
  filter: drop-shadow(1px 1px 10px red);
}

.Ic-Location-icon {
  cursor: pointer;
  text-align: center;
}

.Ic-Location-icon img {
  width: 40px; /* Adjust icon width */
  height: 40px; /* Adjust icon height */
  display: block;
  position: fixed;
  margin-left: 13vw;
  margin-top: 36vh;
  filter: drop-shadow(1px 1px 10px blue); /* Center the icon horizontally */
}

.Ic-Location-icon:hover {
  opacity: 0.8; /* Reduce opacity on hover */
  filter: drop-shadow(1px 1px 10px rgb(255, 0, 238));
}

/* Mobile view adjustments */
@media (max-width: 600px) {
  .Ic-contact-icons {
    flex-direction: column;
    gap: 10px;
    z-index: 1;
    left: 88vw;
    right: 15px;
    top: 55vh;
    position: fixed;
    /* margin-top: 50vh;  */
    
  }

  .Ic-Dial-icon img,
  .Ic-Whatsapp-icon img,
  .Ic-Location-icon img {
    width: 30px; /* Adjust icon width for mobile */
    height: 30px; 
    margin-left: 0;/* Adjust icon height for mobile */
    position: fixed;

  }

  .Ic-Whatsapp-icon img {
    position: fixed;
    width: 35px;
    height: 35px;  
    margin-top: 19vh;  
    margin-left: -0.5vw;
  }

  .Ic-Location-icon img {
    position: fixed;
    margin-top: 27vh;
  }
}
