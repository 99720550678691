.c-img-image-container {
    text-align: center;
    margin: 20px auto;   
    
}
  
  .c-img-image-container img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 4%;
  }


  
  @media (max-width:768px) {
    .c-img-image-container img {
      max-width: 90%;
      margin-top: 5%;
      margin-left: 5%;
      margin-right: 5%;

    }
    .c-img-rights{
      font-size: 12px;
      margin-bottom: 40px;
    }
  }