* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
}

.tabbed-panels {
    display: flex;
    flex-direction: column;
    height: auto;
    overflow: hidden;
    margin-left: 2%;
}

.tabs {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 5px;
    overflow-x: auto;
    white-space: nowrap;
}

.tab {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;
}

.tab span {
    margin-right: 5px;
}

.tab .close {
    margin-left: 5px;
    color: red;
}

.tab-content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
}

.pos-tab {
    display: none;
}

.pos-tab.active {
    display: flex;
    width: 100%;
}

.pos-tab-content {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
}

.header {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
}

.new-bill {
    margin-left: 0;
    padding: 0px 10px;
    cursor: pointer;
    font-size: 22px;
}

.new-bill:hover{
    background-color:#ccc;
    border-radius: 50px;
}

.left-panel {
    display: flex;
    flex-direction: column;
    width: 70%;
    overflow: hidden;
    margin-right: 1%;
}

.right-panel {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30%;
    overflow: hidden;
}

.search-container {
    margin-bottom: 10px;
    position: relative;
}

.search-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.search-icon {
    position: absolute;
    left: 10px;
    color: #888;
}

.search-input {
    padding: 10px 10px 10px 35px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.search-results {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    max-height: 300px;
    overflow-y: auto;
    background: white;
    border: 1px solid #ccc;
    z-index: 1000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 4px 4px;
    width: 100%;
}

.search-results-table {
    border-collapse: collapse;
    width: 100%;
}

.search-results-table th,
.search-results-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.search-results-table th {
    font-weight: bold;
    background-color: #f5f5f5;
}

.search-results-table td {
    white-space: nowrap;
}

.search-result-item {
    cursor: pointer;
}

.search-result-item:hover {
    background-color: #f1f1f1;
}

.table-container {
    overflow: auto;
    height: 75vh;
    max-width: 100%; 
}

.table-container::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.table-container::-webkit-scrollbar-track {
    background: #ffffff;
}

.table-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

.table-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.table-container {
    scrollbar-width: thin;
    scrollbar-color: #888 #ffffff;
}

.item-table {
    border-collapse: collapse;
    width: 100%;
}

.column-header {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    padding: 10px;
    border-bottom: 2px solid #ddd;
}

.item-table th,
.item-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    white-space: nowrap;
}

.customer-details,
.bill-details,
.cash-upi {
    margin-bottom: 1vh;
    background-color: white;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}
.bill-details{
    height: 18vh;
}
.customer-details label,
.bill-details label,
.cash-upi label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.customer-details input,
.bill-details input,
.cash-upi input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.customer-details input {
    margin-bottom: 10px;
}

.cash-upi .change-return {
    margin-bottom: 5px;
    font-size: 14px;
}

.bill-details .sub-total {
    margin-bottom: 5px;
    font-size: 14px;
    text-align: left;
}

.bill-details .total-amount {
    margin-bottom: 5px;
    font-size: 14px;
    text-align: left;
    font-weight: bold;
}

.payment-method {
    margin-bottom: 10px;
}

.payment-method label {
    display: inline-block;
    width: 120px;
    text-align: left;
}

.payment-method select,
.payment-method input[type="text"] {
    width: calc(100% - 145px);
    margin-left: 10px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
}

.bottom-buttons {
    margin-top: auto;
}

.bottom-buttons button {
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    width: 100%;
}

.bottom-buttons .save-print {
    background-color: #5cb85c;
    border: 1px solid #4cae4c;
    color: white;
    margin-bottom: 10px;
}

.delete-button {
    border: none;
    cursor: pointer;
    background: none;
    margin: auto;
}

.error-message {
    color: red;
    font-size: 1em;
    margin-top: -5px;
    margin-bottom: 3px;
    text-align: left;
    margin-left: 2px;
}

.search-result-item.focused {
    background-color: #f0f0f0;
}

.search-result-item.focused .delete-button {
    display: block;
}

.search-result-item .delete-button {
    display: none;
}

.bottom-buttons1 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
}

.bottom-buttons1 .partial-pay {
    background-color: #f0ad4e;
    border: 1px solid #eea236;
    color: white;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
}

.focused-row {
    background-color: #e0e0e0;
}

.buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px 0;
}

.buttons button {
    flex: 1 1 22%;
    padding: 10px;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
}

.buttons .blue-button {
    background-color: #e0f7ff;
    border: 1px solid #00bcd4;
    color: #007bff;
}

/* Existing styles... */

/* Responsive Design for Mobile View */
@media (max-width: 768px) {
    .tabbed-panels {
        margin-left: 5%;
    }

    .left-panel {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .right-panel {
        width: 100%;
    }

    .buttons {
        display: none;
    }

    .table-container {
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none; /* For Firefox */
    }

    .table-container::-webkit-scrollbar {
        display: none; /* For Chrome, Safari, and Opera */
    }
}

@media (max-width: 480px) {
    .tabbed-panels {
        margin-left: 1%;
        margin-right: 0.5%;
        margin-top: 3%;
    }

    .table-container {
        height: 35vh;
    }

    .left-panel,
    .right-panel {
        width: 100%;
    }

    .buttons button {
        flex: 1 1 48%;
    }

    .buttons {
        display: none;
    }
    .bottom-buttons{
        margin-bottom: 20%;
    }

    .bottom-buttons button {
        margin-bottom: 0px;
    }
    .customer-details,
    .bill-details,
    .cash-upi {
        width: 100%;
        margin-bottom: 10px;
    }

    .pos-tab-content {
        flex-direction: column;
    }

    .table-container {
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none; /* For Firefox */
    }

    .table-container::-webkit-scrollbar {
        display: none; /* For Chrome, Safari, and Opera */
    }
}
