/* Add this at the end of your Shoppage.css file */

/* General body styling */
body {
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
}

/* Container for the shop items */
.shop-container {
  width: 70%;
  margin: 0 auto;
  padding: 20px;
  margin-top: 5%;
}

/* Sorting options styling */
.sort-options {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  width: 25%;
}

/* Label for sorting options */
.sort-options label {
  /* margin-right: 5%; */
  font-weight: bold;
  text-align: right;
  /* margin-left: 50vw; */
}

/* Select input for sorting options */
.sort-options select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  width: 15vw;
}

/* Grid layout for product items */
.pcd-product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12vw, 1fr));
  gap: 20px;
}

/* Hover effect on product card */
.pcd-product-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Styling for product images and placeholders */
.pcd-product-card img,
.image-placeholder {
  width: 100%;
  height: 53%;
  cursor: pointer;
  object-fit: cover;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;
}

/* Styling for product info section */
.product-info {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex-grow: 1;
}

/* Styling for product title */
/* Individual product card styling */
.pcd-product-card {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s, box-shadow 0.2s;
  position: relative;
  height: auto; /* Adjust height as per your design */
  max-height: 40vh; /* Maximum height to prevent overflow */
  width: 13vw; /* Adjust width as per your design */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Styling for product title */
.product-info h2 {
  font-size: 13px;
  width: 90%; /* Allow the title to expand */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
}

/* Styling for product category and pricing */
.product-info p {
  margin: 5px 0;
  color: #8c8c8c;
  font-size: 15px;
  width: 90%; /* Allow the category to expand */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


/* Tooltip styling */
.product-info h2:hover+.tooltip,
.tooltip:hover {
  visibility: visible;
  opacity: 1;
}

/* Tooltip content styling */
.tooltip {
  visibility: hidden;
  background-color: rgb(112, 110, 110);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 10px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 10px;
}


/* Styling for original price */
.original-price {
  text-decoration: line-through;
  color: #888;
  margin-right: 10px;
  font-size: 13px;
}

/* Styling for discounted price */
.discounted-price {
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-size: 13px;
}

/* Styling for discount badge */
.discount-badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #d3ffe9;
  color: #03753c;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 13px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Styling for Add to Cart button */
.add-to-cart-button {
  display: block;
  background-color: #ffffff;
  border: 1px solid #949494;
  color: #165978;
  padding: 5px 65px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin-top: 5px;
  cursor: pointer;
  border-radius: 25px;
  transition: background-color 0.3s;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Hover effect for Add to Cart button */
.add-to-cart-button:hover {
  background-color: #e1e1e1;
}

/* Styling for quantity controls */
.quantity-controls {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Styling for quantity buttons */
.quantity-button {
  background-color: #919191;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

/* Styling for quantity display */
.quantity {
  margin: 0 10px;
  font-size: 12px;
}

/* Styling for heart icon */
.heart-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 22px;
  cursor: pointer;
  transition: color 0.3s;
  background-color: white;
  padding: 0 4px;
  border-radius: 50%;
}

/* Categories container styling */
.categories-container {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 5px 0;
  margin-bottom: 20px;
  margin-right: -1vw;
}

/* Categories wrapper styling */
.categories-wrapper {
  display: flex;
  align-items: center;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
  height: 5vh;
}

/* Hide scrollbar for categories wrapper */
.categories-wrapper::-webkit-scrollbar {
  display: none;
}

/* Styling for individual category items */
.category-item {
  padding: 7px 15px;
  margin-right: 10px;
  border-radius: 25px;
  background-color: #e3e3e3;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Hover effect for category items */
.category-item:hover {
  background-color: #f5f5f5;
}

/* Styling for scroll buttons */
.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Hover effect for scroll buttons */
.scroll-button:hover {
  background-color: #dcdcdc;
}

/* Positioning for left scroll button */
.scroll-button.left {
  left: 0;
}

/* Positioning for right scroll button */
.scroll-button.right {
  right: 0;
}


/* ------------------------Tablet-------------------------- */

@media (min-width: 600px) and (max-width: 1199px){
  .shop-container {
    width: 90%;
    padding: 0px;

  }

  .sort-options {
    margin-bottom: 10px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    width: 25%;
  }

  .sort-options label {
    /* margin-right: 5%; */
    font-weight: bold;
    text-align: right;
    /* margin-left: 62vw;   */
  
  }

  .sort-options select {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    width: 25vw;
    font-size: 16px;
  }

  .sort-options option {

    font-size: 10px;
  }


  .pcd-product-grid {
    grid-template-columns: repeat(auto-fill, minmax(25vw, 3fr));
  }

  .pcd-product-card {
    height: 25vh;
    width: 25vw;
    margin-left: 7px;
    
  }

 
}
/* ------------------------Phpne-------------------------- */
@media (max-width: 480px) {

  .shop-container {
    width: 90%;
    padding: 0px;

  }

  .sort-options {
    margin-bottom: 10px;
    margin-top: 75px;
    display: flex;
    align-items: center;
    width: 25%;
  }

  .sort-options label {
    /* margin-right: 5%; */
    font-weight: bold;
    text-align: right;
    /* margin-left: 47vw; */
  }

  .sort-options select {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    width: 25vw;
    font-size: 12px;
  }

  .sort-options option {

    font-size: 9px;
  }


  .pcd-product-grid {
    grid-template-columns: repeat(auto-fill, minmax(30vw, 1fr));
  }

  .pcd-product-card {
    height: 38vh;
    width: 46vw;
    margin-left: -7px;
  }



}