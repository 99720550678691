.add-item-form {
  margin: 15px 15px 10px 0px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: none;
  margin-left: 12%;
  display: flex;
  flex-direction: column;
}

.add-item-form h2 {
  text-align: left;
  margin-bottom: 20px;
}

.add-item-form .form-container {
  flex: 1;
  padding-right: 10px;
}

.container1 {
  display: flex;
  gap: 20px;
  align-items: start;
  margin-bottom: 20px;
}

.input-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 100%;
}

.input-container {
  position: relative;
  margin-bottom: 10px;
  width: 60%;
}

.percentage {
  width: 50%;
}

.input-container.half-width {
  width: 60%;
}

.input-placeholder {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease;
  color: #999;
  pointer-events: none;
}

.input-placeholder.active {
  top: -10px;
  font-size: 12px;
  color: #007bff;
}

.input-with-symbol {
  position: relative;
  display: flex;
  align-items: center;
}

.input-with-symbol .rupee-symbol {
  position: absolute;
  left: 10px;
  color: black;
  font-size: 16px;
  display: none;
}

.input-with-symbol.rupee-symbol-active .rupee-symbol {
  display: block;
}

input.with-rupee {
  padding-left: 25px;
}

input,
textarea,
select {
  padding: 10px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #cccccc;
  border-radius: 4px;
  transition: border-color 0.3s ease, border-width 0.3s ease;
}

input:focus,
textarea:focus,
select:focus {
  border-color: #007bff;
  border-width: 2px;
  outline: none;
}

textarea {
  height: 60px;
}

.form-actions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.form-actions button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
}

.form-actions button:hover {
  background-color: #0056b3;
}

.gst-address {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.enable-shipping {
  display: flex;
  align-items: center;
}

.enable-shipping input {
  margin-right: 10px;
}

.input-with-button {
  position: relative;
  display: flex;
  align-items: center;
}

.input-with-button input {
  flex: 1;
  margin-right: 10px;
}

.input-with-button button {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.input-with-button button:hover {
  background-color: #0056b3;
}


/* Mobile view styles */
@media (max-width: 768px) {
  .add-item-form {
    padding: 10px;
    margin-left: 0;
  }

  .container1 {
    flex-direction: column;
  }

  .input-fields {
    grid-template-columns: 1fr;
    
  }

  .input-container,
  .percentage {
    width: 100%;
  }

  .input-container.half-width {
    width: 100%;
  }
  
  .form-actions {
    display: flex;
    flex-direction: row;
    justify-content:end; /* Distribute buttons evenly */
    gap: 10px; /* Space between buttons */
    align-items: center; /* Align buttons vertically */
  }

  .form-actions button {
    width: calc(50% - 20px); /* Half width minus half of the gap */
    border-radius: 50px;
  }
 
}
