* {
  margin: 0;
  padding: 0;
}

.home-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .add-parties-image {
    display: block;
    margin: 20px auto;
    max-width: 25%;
    height: auto ;

  }
  
  .welcome {
    /* padding: 20px; */
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.189);
    text-align: center;
    margin-left: 21%;
    height: 60vh;
    margin-right: 2%;
  }
  
  .add-party p {
    font-size: 18px;
    color: #666;
    margin-bottom: 20px;
  }
  
  .add-party button {
    background-color: #ff7300;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .add-party button:hover {
    background-color: #0056b3;
  }
  